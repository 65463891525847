/* DayPicker styles */

.DayPicker {
  display: inline-block;
  font-size: 1rem;
}

.DayPicker-wrapper {
  position: relative;

  flex-direction: row;
  padding-bottom: 1em;

  -webkit-user-select: none;

          user-select: none;
}

.DayPicker-Months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.DayPicker-Month {
  display: table;
  margin: 0 1em;
  margin-top: 1em;
  border-spacing: 0;
  border-collapse: collapse;

  -webkit-user-select: none;

          user-select: none;
}

.DayPicker-NavBar {
}

.DayPicker-NavButton {
  position: absolute;
  top: 1em;
  right: 1.5em;
  left: auto;

  display: inline-block;
  margin-top: 2px;
  width: 1.25em;
  height: 1.25em;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  color: #8B9898;
  cursor: pointer;
}

.DayPicker-NavButton:hover {
  opacity: 0.8;
}

.DayPicker-NavButton--prev {
  margin-right: 1.5em;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
}

.DayPicker-NavButton--next {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
}

.DayPicker-NavButton--interactionDisabled {
  display: none;
}

.DayPicker-Caption {
  display: table-caption;
  margin-bottom: 0.5em;
  padding: 0 0.5em;
  text-align: left;
}

.DayPicker-Caption > div {
  font-weight: 500;
  font-size: 1.15em;
}

.DayPicker-Weekdays {
  display: table-header-group;
  margin-top: 1em;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  display: table-cell;
  padding: 0.5em;
  color: #8B9898;
  text-align: center;
  font-size: 0.875em;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
}

.DayPicker-Day {
  display: table-cell;
  padding: 0.5em;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}

.DayPicker-WeekNumber {
  display: table-cell;
  padding: 0.5em;
  min-width: 1em;
  border-right: 1px solid #EAECEC;
  color: #8B9898;
  vertical-align: middle;
  text-align: right;
  font-size: 0.75em;
  cursor: pointer;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: 0.5em;
}

.DayPicker-TodayButton {
  border: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  color: #4A90E2;
  font-size: 0.875em;
  cursor: pointer;
}

/* Default modifiers */

.DayPicker-Day--today {
  color: #D0021B;
  font-weight: 700;
}

.DayPicker-Day--outside {
  color: #8B9898;
  cursor: default;
}

.DayPicker-Day--disabled {
  color: #DCE0E0;
  cursor: default;
  /* background-color: #eff1f1; */
}

/* Example modifiers */

.DayPicker-Day--sunday {
  background-color: #F7F8F8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #DCE0E0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;

  background-color: #4A90E2;
  color: #F0F8FF;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #51A0FA;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #F0F8FF;
}

/* DayPickerInput */

.DayPickerInput {
  display: inline-block;
}

.DayPickerInput-OverlayWrapper {
  position: relative;
}

.DayPickerInput-Overlay {
  position: absolute;
  left: 0;
  z-index: 1;

  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.awssld__container figure, .awssld__content, .awssld__box {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }

.awssld__controls button, .awssld__bullets button {
  outline-color: 0;
  outline-style: none;
  outline-width: 0; }

.awssld {
  --organic-arrow-thickness: 4px;
  --organic-arrow-height: 40px;
  --slider-height-percentage: 60%;
  --loader-bar-color: #851515;
  --loader-bar-height: 6px;
  --control-button-width: 10%;
  --control-button-height: 25%;
  --control-button-opacity: 0.5;
  --control-button-hover-opacity: 0.75;
  --control-button-background: transparent;
  --transition-bezier: cubic-bezier(0.5, 0.075, 0.25, 0.95);
  --slider-transition-duration: 575ms;
  --organic-arrow-color: #6a6a6a;
  --organic-arrow-border-radius: 0;
  --control-bullet-color: #6a6a6a;
  --control-bullet-active-color: #6a6a6a;
  --content-background-color: #2f2f2f; }

.awssld {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%; }
  .awssld__wrapper {
    display: block;
    overflow: hidden;
    position: relative;
    height: 100%;
    width: 100%; }
  .awssld__container {
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: var(--slider-height-percentage); }
    @media all and (max-width: 500px) {
      .awssld__container {
        padding-bottom: calc(var(--slider-height-percentage) * 1.25); } }
  .awssld__startUp {
    background-color: red;
    height: 100%;
    width: 100%; }
    .awssld__startUp > div {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center; }
    .awssld__startUp img {
      width: 35%;
      height: auto; }
  .awssld__content {
    background-color: var(--content-background-color);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center; }
    .awssld__content > img,
    .awssld__content > video {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0; }
  .awssld__controls button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--control-button-width);
    height: var(--control-button-height);
    position: absolute;
    z-index: 10;
    top: calc(50% - (0.5 * var(--control-button-height)));
    border: none;
    background-color: var(--control-button-background);
    color: #fff;
    cursor: pointer; }
    .awssld__controls button .awssld__controls__arrow-left,
    .awssld__controls button .awssld__controls__arrow-right {
      opacity: var(--control-button-opacity); }
    .awssld__controls button:hover .awssld__controls__arrow-left,
    .awssld__controls button:hover .awssld__controls__arrow-right {
      opacity: var(--control-button-opacity-hover); }
  .awssld__controls--active .awssld__controls__arrow-left {
    opacity: var(--control-button-opacity-hover);
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0); }
  .awssld__controls--active .awssld__controls__arrow-right {
    opacity: var(--control-button-opacity-hover);
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0); }
  .awssld__controls--hidden {
    display: none; }
  @media all and (max-width: 520px) {
    .awssld__controls {
      visibility: hidden; } }
  .awssld__bar {
    display: block;
    width: 100%;
    height: var(--loader-bar-height);
    background-color: var(--loader-bar-color);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 101;
    transition: -webkit-transform 3000ms var(--transition-bezier);
    transition: transform 3000ms var(--transition-bezier);
    transition: transform 3000ms var(--transition-bezier), -webkit-transform 3000ms var(--transition-bezier);
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0); }
    .awssld__bar--active {
      -webkit-transform: translate3d(-20%, 0, 0);
              transform: translate3d(-20%, 0, 0); }
    .awssld__bar--end {
      transition-duration: 300ms;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); }
  .awssld__next {
    right: 0; }
  .awssld__prev {
    left: 0; }
  .awssld__box {
    z-index: 1;
    visibility: hidden; }
  .awssld--animated {
    will-change: transform;
    visibility: visible; }
  .awssld--animated-mobile {
    will-change: transform;
    transition: -webkit-transform 325ms cubic-bezier(0.15, 0.65, 0.1, 1);
    transition: transform 325ms cubic-bezier(0.15, 0.65, 0.1, 1);
    transition: transform 325ms cubic-bezier(0.15, 0.65, 0.1, 1), -webkit-transform 325ms cubic-bezier(0.15, 0.65, 0.1, 1); }
  .awssld--active {
    visibility: visible;
    z-index: 2;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  .awssld--moveRight, .awssld--moveLeft {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
  .awssld--moveRight {
    -webkit-animation: slideFromRight var(--slider-transition-duration) both var(--transition-bezier);
            animation: slideFromRight var(--slider-transition-duration) both var(--transition-bezier); }
  .awssld--moveLeft {
    -webkit-animation: slideFromLeft var(--slider-transition-duration) both var(--transition-bezier);
            animation: slideFromLeft var(--slider-transition-duration) both var(--transition-bezier); }
  .awssld--exit {
    z-index: 0; }
    .awssld--exit.awssld--moveLeft {
      -webkit-animation: slideToLeft var(--slider-transition-duration) both var(--transition-bezier);
              animation: slideToLeft var(--slider-transition-duration) both var(--transition-bezier); }
    .awssld--exit.awssld--moveRight {
      -webkit-animation: slideToRight var(--slider-transition-duration) both var(--transition-bezier);
              animation: slideToRight var(--slider-transition-duration) both var(--transition-bezier); }
  .awssld--first .awssld__prev {
    visibility: hidden; }
  .awssld--last .awssld__next {
    visibility: hidden; }
  .awssld--fill-parent {
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    top: 0;
    left: 0; }
    .awssld--fill-parent .awssld__container {
      height: 100%;
      padding: 0; }
  .awssld__bullets {
    position: absolute;
    bottom: -40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .awssld__bullets button {
      padding: 0;
      display: block;
      width: 16px;
      height: 16px;
      margin: 5px;
      border-radius: 50%;
      background: var(--control-bullet-color);
      text-indent: -9999px;
      overflow: hidden;
      cursor: pointer;
      border: none;
      transition: background-color 0.175s ease-out, -webkit-transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45);
      transition: transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45), background-color 0.175s ease-out;
      transition: transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45), background-color 0.175s ease-out, -webkit-transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45); }
      .awssld__bullets button:hover {
        -webkit-transform: scale(1.2);
                transform: scale(1.2); }
    .awssld__bullets .awssld__bullets--loading {
      -webkit-transform: scale(1.2);
              transform: scale(1.2); }
    .awssld__bullets .awssld__bullets--active {
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
      background: var(--control-bullet-active-color); }
      .awssld__bullets .awssld__bullets--active:hover {
        -webkit-transform: scale(1.5);
                transform: scale(1.5); }
  .awssld__controls__arrow-left, .awssld__controls__arrow-right {
    width: 100%;
    height: var(--organic-arrow-height);
    position: relative;
    display: block;
    transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out 0.125s;
    transition: transform 0.2s ease-out 0.125s, opacity 0.2s ease-out;
    transition: transform 0.2s ease-out 0.125s, opacity 0.2s ease-out, -webkit-transform 0.2s ease-out 0.125s; }
    .awssld__controls__arrow-left:before, .awssld__controls__arrow-left:after, .awssld__controls__arrow-right:before, .awssld__controls__arrow-right:after {
      content: ' ';
      position: absolute;
      right: calc( 50% - ( 0.7071 * (var(--organic-arrow-height) + var(--organic-arrow-thickness)) ) / 2);
      height: 100%;
      border-radius: var(--organic-arrow-border-radius);
      width: var(--organic-arrow-thickness);
      background-color: var(--organic-arrow-color);
      transition: background-color 0.15s ease-out, -webkit-transform 0.15s ease-out;
      transition: transform 0.15s ease-out, background-color 0.15s ease-out;
      transition: transform 0.15s ease-out, background-color 0.15s ease-out, -webkit-transform 0.15s ease-out; }
    .awssld__controls__arrow-left:before, .awssld__controls__arrow-right:before {
      -webkit-transform-origin: 100% 100% 0;
              transform-origin: 100% 100% 0;
      top: -50%;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg); }
    .awssld__controls__arrow-left:after, .awssld__controls__arrow-right:after {
      -webkit-transform-origin: 100% 0% 0;
              transform-origin: 100% 0% 0;
      top: 50%;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }
  .awssld__controls__arrow-right--active {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0); }
    .awssld__controls__arrow-right--active:after {
      -webkit-transform: rotate(90deg) translate3d(50%, 0, 0) !important;
              transform: rotate(90deg) translate3d(50%, 0, 0) !important; }
    .awssld__controls__arrow-right--active:before {
      -webkit-transform: rotate(-90deg) translate3d(50%, 0, 0) !important;
              transform: rotate(-90deg) translate3d(50%, 0, 0) !important; }
  .awssld__controls__arrow-left:before, .awssld__controls__arrow-left:after {
    right: auto;
    left: calc( 50% - ( 0.7071 * (var(--organic-arrow-height) + var(--organic-arrow-thickness)) ) / 2); }
  .awssld__controls__arrow-left:before {
    -webkit-transform-origin: 0 100% 0;
            transform-origin: 0 100% 0;
    top: -50%;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .awssld__controls__arrow-left:after {
    -webkit-transform-origin: 0 0 0;
            transform-origin: 0 0 0;
    top: 50%;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .awssld__controls__arrow-left--active {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0); }
    .awssld__controls__arrow-left--active:after {
      -webkit-transform: rotate(-90deg) translate3d(-50%, 0, 0) !important;
              transform: rotate(-90deg) translate3d(-50%, 0, 0) !important; }
    .awssld__controls__arrow-left--active:before {
      -webkit-transform: rotate(90deg) translate3d(-50%, 0, 0) !important;
              transform: rotate(90deg) translate3d(-50%, 0, 0) !important; }
  .awssld__controls button:hover .awssld__controls__arrow-left:before {
    opacity: 1;
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg); }
  .awssld__controls button:hover .awssld__controls__arrow-left:after {
    opacity: 1;
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg); }
  .awssld__controls button:hover .awssld__controls__arrow-right:before {
    opacity: 1;
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg); }
  .awssld__controls button:hover .awssld__controls__arrow-right:after {
    opacity: 1;
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg); }
  .awssld__timer {
    --timer-delay: 2000ms;
    --timer-release: 200ms;
    --timer-height: 4px;
    --timer-background-color: rgba(0, 0, 0, 0.15);
    width: 100%;
    height: var(--timer-height);
    background-color: var(--timer-background-color);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
    .awssld__timer--animated {
      will-change: transform;
      transition: -webkit-transform var(--timer-delay) linear;
      transition: transform var(--timer-delay) linear;
      transition: transform var(--timer-delay) linear, -webkit-transform var(--timer-delay) linear; }
    .awssld__timer--run {
      -webkit-transform: translateX(0);
              transform: translateX(0); }
    .awssld__timer--fast {
      transition: -webkit-transform calc(var(--timer-release) / 2) linear;
      transition: transform calc(var(--timer-release) / 2) linear;
      transition: transform calc(var(--timer-release) / 2) linear, -webkit-transform calc(var(--timer-release) / 2) linear;
      -webkit-transform: translateX(-0.00001px);
              transform: translateX(-0.00001px); }
    .awssld__timer--end {
      -webkit-transform: translateX(-0.0001px);
              transform: translateX(-0.0001px);
      transition: -webkit-transform var(--timer-release) linear;
      transition: transform var(--timer-release) linear;
      transition: transform var(--timer-release) linear, -webkit-transform var(--timer-release) linear; }
    .awssld__timer--hidden {
      display: none; }

@-webkit-keyframes slideFromLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes slideFromLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@-webkit-keyframes slideFromRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes slideFromRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@-webkit-keyframes slideToLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0); } }

@keyframes slideToLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0); } }

@-webkit-keyframes slideToRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0); } }

@keyframes slideToRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0); } }


.alice-carousel .animated {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.alice-carousel .animated-out {
  z-index: 1; }

.alice-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    visibility: hidden; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    visibility: hidden; } }

.alice-carousel {
  position: relative;
  width: 100%;
  margin: auto;
  direction: ltr; }

.alice-carousel__wrapper {
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  box-sizing: border-box;
  width: 100%;
  height: auto; }

.alice-carousel__stage {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  transform-style: flat;
  -webkit-transform-style: flat;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden; }
  .alice-carousel__stage-item {
    position: relative;
    display: inline-block;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    vertical-align: top;
    white-space: normal;
    line-height: 0; }
    .alice-carousel__stage-item * {
      line-height: initial; }
    .alice-carousel__stage-item.__hidden {
      opacity: 0;
      overflow: hidden; }

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  display: inline-block;
  box-sizing: border-box;
  width: 50%;
  padding: 10px 5px; }
  .alice-carousel__prev-btn [data-area]::after,
  .alice-carousel__next-btn [data-area]::after {
    position: relative;
    content: attr(data-area);
    text-transform: capitalize; }

.alice-carousel__prev-btn {
  text-align: right; }

.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
  display: inline-block;
  cursor: pointer;
  padding: 5px;
  margin: 0;
  color: #465798; }
  .alice-carousel__prev-btn-item:hover,
  .alice-carousel__next-btn-item:hover {
    color: darkred; }
  .alice-carousel__prev-btn-item.__inactive,
  .alice-carousel__next-btn-item.__inactive {
    opacity: 0.4;
    pointer-events: none; }

.alice-carousel__play-btn {
  position: absolute;
  top: 30px;
  left: 20px;
  display: inline-block; }
  .alice-carousel__play-btn:hover {
    cursor: pointer; }
  .alice-carousel__play-btn-wrapper {
    position: relative;
    width: 32px;
    height: 32px;
    padding: 10px;
    border-radius: 50%;
    background-color: #fff; }

.alice-carousel__play-btn-item {
  position: absolute;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border: 0;
  outline: none;
  background: transparent; }
  .alice-carousel__play-btn-item::before, .alice-carousel__play-btn-item::after {
    position: absolute;
    pointer-events: none;
    display: block;
    width: 0;
    height: 0;
    content: '';
    transition: all 0.4s linear;
    border-width: 8px 0 8px 15px;
    border-style: solid;
    border-color: transparent;
    border-left-color: #465798; }
  .alice-carousel__play-btn-item::before {
    left: 5px;
    height: 14px; }
  .alice-carousel__play-btn-item::after {
    top: 7px;
    left: 18px; }
  .alice-carousel__play-btn-item.__pause::before, .alice-carousel__play-btn-item.__pause::after {
    height: 30px;
    border-width: 0 0 0 10px; }
  .alice-carousel__play-btn-item.__pause::after {
    top: 0;
    left: 18px; }

.alice-carousel__dots {
  margin: 30px 3px 5px;
  padding: 0;
  list-style: none;
  text-align: center; }
  .alice-carousel__dots > li {
    display: inline-block; }
  .alice-carousel__dots-item:not(.__custom) {
    width: 8px;
    height: 8px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #e0e4fb; }
    .alice-carousel__dots-item:not(.__custom):not(:last-child) {
      margin-right: 20px; }
    .alice-carousel__dots-item:not(.__custom):hover, .alice-carousel__dots-item:not(.__custom).__active {
      background-color: #6e7ebc; }

.alice-carousel__slide-info {
  position: absolute;
  top: 20px;
  right: 20px;
  display: inline-block;
  padding: 5px 10px;
  color: #465798;
  border-radius: 5px;
  background-color: rgba(224, 228, 251, 0.6); }
  .alice-carousel__slide-info-item {
    vertical-align: middle;
    line-height: 0; }

